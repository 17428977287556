// src/config/environment.js
export const environment = {
    production: true,
    baseUrl: 'https://admin.letsdrive.ae/api/',
    // Uncomment the line below for the beta environment
    // baseUrl: 'https://admin.beta.letsdrive.ae/api/',
    webUrl: 'https://letsdrive.ae/',
    firebase: {
      apiKey: "AIzaSyAGjwy-C7rwO7L6Q85_SQN5nGg96luvKzY",
      authDomain: "lets-drive-371408.firebaseapp.com",
      projectId: "lets-drive-371408",
      storageBucket: "lets-drive-371408.firebasestorage.app",
      messagingSenderId: "916977580664",
      appId: "1:916977580664:web:82263539ace9184cf98b8d",
      measurementId: "G-4TB7Z1RLLG",
      vapidKey:
        'BL318I6xNONNfhIkFH5ppTO29hFK2ztd9Y7dX2JgJf03iJJykWQ6o8yNfJ48ZmUrUNsHRBfcQ9slwJtoxD5-QR0',
    },
  };
  
  export const PATHS = '';
  