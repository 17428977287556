import React, { useEffect, useState } from 'react';
import { useTranslation, } from "react-i18next";
import ReCAPTCHA from 'react-google-recaptcha';
import { MemoryRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GoogleSigninButton, statusCodes, } from '@react-native-google-signin/google-signin';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import '../assets/css/english.css';
import '../assets/css/arabic.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import './login.css';
import close from '../assets/images/home/close.png';
import fb from '../assets/images/home/fb-login.png';
import eyepass from '../assets/images/home/eye-password.png';
import eyeopen from '../assets/images/home/eye-open.png';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from '../service/authService';
// import { getDeviceToken } from '../service/devicetoken';
import { getDeviceToken } from '../service/firebase';
import google from '../assets/images/home/google-login.png';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';


const Login = ({ user, socialUser, language }) => {
  // const history = MemoryRouter();
  const navigate = useNavigate();
  const [blnSendMail, setBlnSendMail] = useState(false);
  const [metadata, setMetaData] = useState(() => {
    const savedMetadata = localStorage.getItem('metadata');
    return savedMetadata ? JSON.parse(savedMetadata) : { meta_details: [] };
  });

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const [form, setForm] = useState({
    email: '',
    password: '',
    device_token: ''
  });
  const [showLogPassword, setShowLogPassword] = useState(false);
  const [blnSubmitted, setBlnSubmitted] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [invaliUserError, setInvaliUserError] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [checkCaptcha, setCheckCaptcha] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const goToRegister = () => {
    const url = currentLanguage === 'ar' ? `/ar/register` : `/en/register`;
    window.location.href = url;
    // navigate('/register');
  }


  const handleVerify = (token) => {
    if (token) {
      setCheckCaptcha(true);

    } else {
      setCheckCaptcha(false);

    }
  };

  const goToFoeget = () => {
    const url = currentLanguage === 'ar' ? `/ar/forget` : `/en/forget`;
    window.location.href = url;
    // navigate('/forget');
  }
  const responseFacebook = (response) => {
    // console.log(response);

    const body = {
      email: response.email,
      token: response.accessToken,
      type: 'FACEBOOK',
      name: response.name,
      photoUrl: response.picture.data.url,
    }
    console.log(body);
    AuthService.socialLogin(body)
    .then((res) => {
      console.log(res.data);
      if (res.data.errorCode === 0) {
        // localStorage.setItem('loggedIn', JSON.stringify(true));
        // localStorage.setItem('token', res.data.data.token);
        // toast.success(res.data.message);

        // navigate('/login');
        // const path = '/';

        // const params = new URLSearchParams(window.location.search);
        // const redirectUrl = params.get('redirect_url');
        // if (redirectUrl) {
        //   // Redirect back to the original page after login
        //   window.location.href = decodeURIComponent(redirectUrl);
        // } else {
        //   // Fallback to a default page if there's no redirect URL
        //   window.location.href = path;
        // }

      }
    })
  }

  const handleSubmit = (e) => {
    // console.log("lechalu");

    e.preventDefault();
    // if (!checkCaptcha) {
    //   toast.error('Verify Captcha');
    //   return;
    // }

    setBlnSubmitted(true);

    if (!form.email) {
      // console.log("email");
      setEmailErrorMsg(true);
    }
    if (!form.password) {
      // console.log("password");
      setPasswordErrorMsg(true);
    }
    if (form.email && form.password) {
      setBlnSendMail(true);
      const data = {
        email: form.email,
        password: form.password,
        device_token: getDeviceToken(), // Retrieve the device token
      };
      // console.log(data);

      AuthService.login(data).then((res) => {
        // console.log('Response received:', res);
        // console.log('Response data:', res.data);

        const { errorCode, data } = res.data; // Destructure the response data

        if (errorCode === 0) {
          // console.log("one");
          setEmailErrorMsg(false);
          setPasswordErrorMsg(false);
          setInvalidLogin(false);

          localStorage.setItem('loggedIn', JSON.stringify(true));
          localStorage.setItem('token', data.token);
          setForm({ email: '', password: '' });

          const path = '/';
          // const path = '/';

          const params = new URLSearchParams(window.location.search);
          const redirectUrl = params.get('redirect_url');
          if (redirectUrl) {
            // Redirect back to the original page after login
            window.location.href = decodeURIComponent(redirectUrl);
          } else {
            // Fallback to a default page if there's no redirect URL
            window.location.href = path;
          }
          // Assuming you have jQuery to hide the modal
          // $('#loginModal').modal('hide'); 

          // if (res.redUrl) {
          //   // console.log("two");
          //   console.log(res.redUrl);
          //   navigate(res.redUrl);
          // } else {
          //   // console.log("three");
          //   // window.location.reload();
          //   // navigate('/');
          // }
          // setBlnSendMail(false);
        } else if (errorCode === 1) {
          setBlnSendMail(false);
          // console.log("four");
          setInvalidLogin(true);
        } else if (errorCode === 2) {
          setBlnSendMail(false);
          // console.log("five");
          setInvaliUserError(res.data.message);
        }
      }).catch((error) => {
        setBlnSendMail(false);
        console.error('Error during login:', error);
      });
    }
  };


  const showLoginPassword = () => setShowLogPassword(true);
  const hideLoginPassword = () => setShowLogPassword(false);

  const clearInputs = () => {
    setForm({
      email: '',
      password: '',
    });
  };

  const handleFacebookLogin = () => {
    window.FB.login((response) => {
      // console.log(response);
      if (response.status === 'connected') {
        setAuthToken(response.authResponse.accessToken);
        // The user is logged in
        // console.log('User logged in with Facebook:', response);
        fetchUserData(response.authResponse.accessToken);

      } else {
        // The user is not logged in
        console.log('User not logged in');
      }
    }, { scope: 'public_profile,email' });
  };

  const fetchUserData = (accessToken) => {
    window.FB.api('/me', { fields: 'id,name,email,picture,birthday,location,gender,hometown' }, (userInfo) => {
      // console.log('User Info:', userInfo);
      const body = {
        email: userInfo.email,
        token: accessToken,
        type: 'FACEBOOK',
        name: userInfo.name,
        photoUrl: userInfo.picture.data.url,
      }
      // console.log(body);

      AuthService.socialLogin(body)
        .then((res) => {
          // console.log(res.data);
          if (res.data.errorCode === 0) {
            localStorage.setItem('loggedIn', JSON.stringify(true));
            localStorage.setItem('token', res.data.data.token);
            toast.success(res.data.message);

            navigate('/login');
            const path = '/';

            const params = new URLSearchParams(window.location.search);
            const redirectUrl = params.get('redirect_url');
            if (redirectUrl) {
              // Redirect back to the original page after login
              window.location.href = decodeURIComponent(redirectUrl);
            } else {
              // Fallback to a default page if there's no redirect URL
              window.location.href = path;
            }

          }
        })
      // Here you can use the user info, e.g., store it in your app's state
    });
  };


  const handleSuccess = (response) => {
    if (response.credential) {
      const idToken = response.credential; // Retrieve the id_token
      // console.log("ID Token:", idToken);

      // Call your function if the idToken is valid
      getInfo(idToken);
    } else {
      console.error("No credential received in response.");
    }
  };


  const handleFailure = (error) => {
    console.error("Login failed:", error);
  };

  // const login = useGoogleLogin({
  //   onSuccess: tokenResponse => getInfo(tokenResponse.access_token),
  //   // onSuccess: tokenResponse => console.log(tokenResponse),
  // });

  const getInfo = async (token) => {
    const devicetoken = localStorage.getItem('deviceToken');

    // console.log(token);
    try {
      const res = await axios.get(
        `https://oauth2.googleapis.com/tokeninfo?id_token=${token}`,

      );
      //   console.log(res.data.name);
      // const name = res.data.name || 'Anonymous User'; // Provide a default value if name is missing

      const body = {

        name: res.data.name,
        email: res.data.email,
        token: token,
        type: 'GOOGLE',
        // device_token: devicetoken

      };
      // console.log(res.data);
      // console.log(res.data);
      AuthService.socialLogin(body)
        .then((res) => {
          // console.log(res.data.data.token);
          if (res.data.errorCode === 0) {
            localStorage.setItem('loggedIn', JSON.stringify(true));
            localStorage.setItem('token', res.data.data.token);
            toast.success(res.data.message);

            // navigate('/login');
            const path = '/';

            const params = new URLSearchParams(window.location.search);
            const redirectUrl = params.get('redirect_url');
            if (redirectUrl) {
              // Redirect back to the original page after login
              window.location.href = decodeURIComponent(redirectUrl);
            } else {
              // Fallback to a default page if there's no redirect URL
              window.location.href = path;
            }

          }
        })



    } catch (err) {
      // console.log(err);
    }
  };

  const goToHome = () => {
    navigate(currentLanguage === 'ar' ? '/ar' : '/en')

    localStorage.setItem('activeMenu', 'home');

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`language-${currentLanguage}`}>
        <Helmet>
          <title>{metadata?.meta_details?.title || 'Login'}</title>
          <meta name="title" content={metadata?.meta_details?.title || 'Login'} />
          <meta name="keywords" content={metadata?.meta_details?.keywords || 'default keywords'} />
          <meta name="description" content={metadata?.meta_details?.description || 'default description'} />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <section className="login-modal">
          <div className="container" style={{ textAlign: 'start' }}>
            <div className="modal-header">
              <a
                // onClick={/* Add your close function here */}
                onClick={goToHome}
                className="login-close"
              >
                <img src={close} alt="" />
              </a>
            </div>
            <div className="modal-body">
              <h5>{t("HOME.LOGIN")}</h5>
              <div className="login-here">
                <p>{t("HOME.LOGINVIA")}</p>
                <div className="social-media">
                  {!user && (
                    <div className="box">
                      {/* <img src={google} onClick={() => login()}></img> */}
                      <GoogleLogin
                        useOneTap
                        onSuccess={handleSuccess}
                        onError={handleFailure}
                        type="icon"            // Show only the icon
                        size="large"           // Adjust the size if needed
                        text=""                 // Empty text to remove label
                        shape="circle"          // Circular button for the icon-only look
                        logo_alignment="center"
                      />

                      {/* <GoogleSigninButton type="icon" size="medium" /> */}
                    </div>
                  )}
                  {!user && (
                    <div className="box">
                      <a
                        onClick={handleFacebookLogin}
                      >
                        <img src={fb} alt="" />
                      </a>
                      {/* <FacebookLogin
                        appId="1258414058215613"
                        autoLoad
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                          <button onClick={renderProps.onClick}>
                            <img src={fb} alt="" />
                          </button>
                        )}
                      /> */}
                    </div>
                  )}

                </div>
                <p className="another-option">{t("HOME.OR")}</p>
                <div className="login-form">
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <label htmlFor="InputEmail">{t("CONTACT.EMAIL")}*</label>
                      <input
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                        className="form-control"
                        id="InputEmail1"
                        aria-describedby="emailHelp"
                        placeholder={t("PLACEHOLDER.EMAIL")}
                      />
                    </div>
                    {blnSubmitted && !form.email && (
                      <span className="validatorsg">{t("VALIDATION.EMAILREQUIRED")}</span>
                    )}
                    {blnSubmitted && form.email && !/\S+@\S+\.\S+/.test(form.email) && (
                      <span className="validatorsg">{t("VALIDATION.INVALIDEMAIL")}</span>
                    )}
                    <div className="form-group">
                      <label htmlFor="InputPassword">{t("FORGOTPASSWORD.PASSWORD")}*</label>
                      <input
                        type={showLogPassword ? "text" : "password"}
                        autoComplete='password'
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                        className="form-control"
                        id="InputPassword"
                        placeholder={t("FORGOTPASSWORD.PASSWORD")}
                      />
                      <span className="toggle">
                        <img
                          onClick={showLogPassword ? hideLoginPassword : showLoginPassword}
                          src={showLogPassword ? { eyepass } : { eyeopen }}
                          alt=""
                        />
                        <a onClick={showLogPassword ? hideLoginPassword : showLoginPassword}>
                          {showLogPassword ? t("FORGOTPASSWORD.HIDE") : t("FORGOTPASSWORD.SHOW")}
                        </a>
                      </span>
                    </div>
                    {blnSubmitted && !form.password && (
                      <span className="validatorsg">{t("VALIDATION.PASSWORDREQUIRED")}</span>
                    )}
                    {invalidLogin && (
                      <span className="validatorsg">{t("VALIDATION.INVALIDCRED")}</span>
                    )}
                    {!invalidLogin && invaliUserError && (
                      <span className="validatorsg">{invaliUserError}</span>
                    )}
                    <div className="forget-password">
                      <a
                        onClick={goToFoeget}
                      //   onClick={() => history.push(language === 'ar' ? '/ar/forgot-password' : '/en/forgot-password')}
                      >
                        {t("FORGOTPASSWORD.FORGOTPASSWORD")}?
                      </a>
                    </div>

                    <div className="login-btn">
                      <button type="submit" className={`btn btn-primary ${blnSendMail ? 'btn-loading' : ''}`}>
                        {t("HOME.LOGIN")}
                      </button>
                    </div>
                    <div className="register-here">
                      <p>
                        {t("FORGOTPASSWORD.DIDNTHAVEACCOUNT")}?
                        <a
                          onClick={goToRegister}
                        // onClick={/* Add your showSignUp function here */}
                        >
                          {t("FOOTER.REGISTER")}
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer /> {/* Add ToastContainer here */}

        </section>
      </div>
    </>
  );
}

export default Login;