// src/utils/urlHelpers.js

export const generateLocalizedUrl = (fullUrl, currentLanguage) => {
    // Define possible base URLs
    const baseUrls = ['https://www.letsdrive.ae/', 'https://letsdrive.ae/'];
  
    // Determine if currentLanguage is valid
    const languagePrefix = (currentLanguage === 'ar' || currentLanguage === 'en') ? currentLanguage : 'en';
  
    // Find the matching base URL and strip it from fullUrl to get the dynamic path
    const baseUrl = baseUrls.find(url => fullUrl.startsWith(url));
    const dynamicPath = baseUrl ? fullUrl.replace(baseUrl, '') : fullUrl;
  
    // Return the full URL with the language prefix
    return `${baseUrl}${languagePrefix}/${dynamicPath}`;
  };
  