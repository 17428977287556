// src/config/AppEndPoints.js
const AppEndPoints = {
    profile: 'customer/profile',
    profile_update: 'customer/profile/update',
    booking: 'book',
    get_doc: 'customer/profile/documents',
    upload_doc: 'book/documents/upload',
    confirm_booking: 'book/confirm',
    categories: 'categories',
    bookings: '',
    cars: 'cars',
    mybookings: 'customer/profile/bookings',
    enquiry: 'contact-us/enquiry/store',
    enquiry_for: 'contact-us/enquiry-for',
    blogs: 'blogs',
    hot_deals: 'hot-offers',
    about: 'about-us',
    contact: 'contact-us',
    forgot_password: 'customer/forgot-password',
    password_change: 'customer/reset-password',
    faq: 'faqs',
    send_email_otp: 'customer/email/send',
    email_verify: 'customer/verify',
    register_verify: 'customer/register/verify',
    head_tags: 'tags/head',
    body_tags: 'tags/body',
    privacy_policy: 'privacy-policy',
    terms_and_conditions: 'terms-and-conditions',
    notification: 'customer/notifications',
    notificationRead: 'customer/notifications/read',
    upload_document: 'customer/profile/documents/upload',
    search: 'search',
    book_status: 'book/status',
    all_cities: 'all-cities',
    cities: 'cities',
    header: 'header',
    footer: 'footer',
    urls: 'urls',
    brands:'brands',
    categories:'categories',
    home: 'home',
    hotofferEnquiry: 'hot-offers/enquiry'
  };
  
  export default AppEndPoints;
  