import React, { useEffect, useState } from 'react';
import '../assets/css/english.css';
import '../assets/css/arabic.css';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { useTranslation, } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import bann from '../assets/images/hot-deal/hotban.png';
import hotdeal from '../assets/images/hot-deal/hot-deals.png';
import mobhot from '../assets/images/hot-deal/mob.png';
import tick from '../assets/images/home/tick.svg';
import whatsapp from '../assets/images/home/whatsapp.png';
import prev from '../assets/images/home/latest-prev.png';
import next from '../assets/images/home/latest-next.png';
import offer from '../assets/images/hot-deal/offone.png';
import AppEndPoints from '../config/AppEndPoints';
import ApiService from '../service/ApiService';
import '../index.css';
import StaticMethod from '../service/staticmethod';
import BookSection from '../booking-flow/booking-sec';

const HottOffers = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;
    const [openBookSec, setOpenBookSec] = useState(false);
    const [carId, setCarId] = useState(null);
    const [brandName, setBrandName] = useState('');
    const [carName, setCarName] = useState('');
    const [carImg, setCarImg] = useState('');
    const [bannImage, setBannImage] = useState('');
    const [addOns, setAddOns] = useState([]);
    const [hotoffer, setHotoffer] = useState({ hot_offers: [] });
    const [metadata, setMetaData] = useState({});


    const [car, setCar] = useState({ cars: [] });


    const close = () => {
        setOpenBookSec(false);
    };


    const goToHome = () => {
        navigate(currentLanguage === 'ar' ? '/ar' : '/en')

    }


    const data = {
        hot_offers: [
            {
                slug: 'offer-1',
                id: '1',
                image_url: { offer }
            },
            {
                slug: 'offer-2',
                id: '2',
                image_url: { offer }
            },
            // Add more offers as needed
        ],
    };

    const gotoFeatures = (slug, id) => {
        navigate(currentLanguage === 'ar' ? `/ar/special-offers/${slug}` : `/en/special-offers/${slug}`);
        sessionStorage.setItem('offer-slug', id);

        // Logic to handle navigation to features
        // console.log(`Navigating to features of ${slug} with id ${id}`);
    };

    const openBookingModal = async (data) => {
        // console.log(data);

        const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
        // console.log(loggedIn);
        if (loggedIn) {
            // If logged in, set the car details and open the booking section
            setCarId(data.id);
            setBrandName(data.brand_name);
            setCarName(data.name);
            setCarImg(data.thumbnail_image_url);
            setAddOns(data.addOns);
            setOpenBookSec(true);

        } else {
            // If not logged in, redirect to login page and pass current URL as a redirect parameter
            const currentUrl = window.location.href; // Capture the current page URL
            const redirectUrl = encodeURIComponent(currentUrl); // Encode the URL
            const loginUrl = currentLanguage === 'ar' ? `/ar/login?redirect_url=${redirectUrl}` : `/en/login?redirect_url=${redirectUrl}`;
            window.location.href = loginUrl;
        }
    };

    const openWhatsApp = (event) => {
        event.preventDefault();
        // Logic to open WhatsApp chat
    };

    const goToDetails = (slug) => {
        // navigate('/productdetails');
        const path = `car-details/${slug}`
        navigate(currentLanguage === 'ar' ? `/ar/${path}` : `/en/${path}`);
    
        
    
    
      }

    const hotdealConfig = {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 10,

        navigation: {
            nextEl: '.slider-nex',
            prevEl: '.slider-pre',
        },
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10,
            },
        },
    }


    const fetchData = (lan) => {
        const headers = {

            'X-Localization': currentLanguage, // Example header to specify language
            // Add other headers as needed
        };
        ApiService.getData(AppEndPoints.hot_deals, headers)
            .then((res) => {
                setHotoffer(res.data.data);
                setMetaData(res.data.data);
                setCar(res.data.data);
                setBannImage(res.data.data.banner_image_url)
                // console.log(res.data.data);
                // console.log(res.data.data.banner_image_url);

            })
            .catch((error) => {
                console.error('Error fetching the about details:', error);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(currentLanguage);

    }, [currentLanguage]);

    return (
        <>
            <div className={`language-${currentLanguage}`}>
            <Helmet>
          <title>{metadata?.meta_title || 'Hot Offers'}</title>
          <meta name="title" content={metadata?.meta_title || 'Hot Offers'} />
          <meta name="keywords" content={metadata?.meta_keywords || 'default keywords'} />
          <meta name="description" content={metadata?.meta_description || 'default description'} />
          <meta name="robots" content="index, follow" />
        </Helmet>
                <div class="hot-deals-section">
                    {/*  <!-----Banner----->*/}
                    <section className="banner">
                        <img src={bann} style={{
                            width: '100%',              // Ensures the image takes up the full width of the section
                            height: '100%',             // Ensures the image takes up the full height of the section
                            objectFit: 'cover',         // Ensures the image covers the entire section without distortion
                            backgroundSize: 'cover',    // Ensures the background is fully covered (can be omitted here)

                        }}></img>
                    </section>
                    {/*  <!-----End Banner----->*/}

                    {/*  <!------Breadcrumbs------>*/}
                    <h1 className="main-heading">
                        <img src={hotdeal} className="d-lg-block d-none"></img>
                        <img src={mobhot} className="d-lg-none d-block"></img>
                    </h1>

                    <ul className="breadcrumb">
                        <li>
                            <a
                                onClick={goToHome}
                            >
                                {t('HOME.HOME')}
                            </a>
                        </li>
                        <li>
                            <a
                                class="active"
                            >
                                {t('HOTOFFER.OFFERS')}
                            </a>
                        </li>
                    </ul>
                    {/*  <!------End Breadcrumbs------>*/}


                    {/*  <!-------------hot deals slider--------->*/}
                    <section className="hotDealslider">
                        <div className="container position-relative" style={{ textAlign: 'start' }}>
                            <Swiper {...hotdealConfig}
                                modules={[Navigation, Pagination,]}

                                navigation={{
                                    nextEl: '.slider-nex',
                                    prevEl: '.slider-pre'
                                }}
                                autoplay={true}
                                pagination={{ clickable: true }}
                            // scrollbar={{ draggable: true }}
                            >
                                {car.cars.map((car, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="box">
                                            <div className="box-img"
                                            onClick={() => goToDetails(car.slug)}
                                            >
                                                <img src={car.thumbnail_image_url} alt="" className="w-100" />
                                            </div>
                                            <div className="box-content">
                                                <span>{car.category_name}</span>
                                                <h4 onClick={() => goToDetails(car.slug)}>
                                                    {car.brand_name} {car.name}
                                                </h4>
                                                <div className="card-spec">
                                                    {car.filters.map((filter, index) => (
                                                        <p key={index}>
                                                            <img src={filter.icon_url} alt="" />
                                                            {filter.name}
                                                        </p>
                                                    ))}
                                                </div>
                                                <div className="card-price">
                                                    <div className="offer-details">
                                                        <span>
                                                            <s>{t('BOOKNOW.AED')} {car.original_daily_price}</s>
                                                        </span>
                                                        <h5>{t('BOOKNOW.AED')} {car.daily_price}</h5>
                                                        <p>{t('PRODUCT.DAY')}</p>
                                                    </div>
                                                    <div className="offer-details">
                                                        <span>
                                                            <s>{t('BOOKNOW.AED')} {car.original_weekly_price}</s>
                                                        </span>
                                                        <h5>{t('BOOKNOW.AED')} {car.weekly_price}</h5>
                                                        <p>{t('PRODUCT.WEEK')}</p>
                                                    </div>
                                                    <div className="offer-details">
                                                        <span>
                                                            <s>{t('BOOKNOW.AED')} {car.original_monthly_price}</s>
                                                        </span>
                                                        <h5>{t('BOOKNOW.AED')} {car.monthly_price}</h5>
                                                        <p>{currentLanguage === 'en' ? t('PRODUCT.MONTH') : t('PRODUCT.MONTHLY')}</p>
                                                    </div>
                                                </div>
                                                {car?.fine_deposit != null || car?.fine_deposit == 0 &&
                                                    <div className="findDeposite">
                                                        <p>
                                                            {t('HOME.FINEDEPOSIT')} :<span>{t('BOOKNOW.AED')} {car.fine_deposit}</span>
                                                        </p>
                                                    </div>}

                                                <div className="advantages">
                                                    <div className="box-dropdown">
                                                        <p className="dropbtn dropup">
                                                            <img src={tick} alt="" />
                                                            {car.feature_1}
                                                        </p>
                                                        <div className="dropdown-content">
                                                            <p>{car.feature_description_1}</p>
                                                        </div>
                                                    </div>
                                                    <div className="box-dropdown">
                                                        <p className="dropbtn dropup">
                                                            <img src={tick} alt="" />
                                                            {car.feature_2}
                                                        </p>
                                                        <div className="dropdown-content">
                                                            <p>{car.feature_description_2}</p>
                                                        </div>
                                                    </div>
                                                    <div className="box-dropdown">
                                                        <p className="dropbtn dropup">
                                                            <img src={tick} alt="" />
                                                            {car.feature_3}
                                                        </p>
                                                        <div className="dropdown-content">
                                                            <p>{car.feature_description_3}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="connect-us">
                                                    <a
                                                        onClick={(e) => openBookingModal(car, e)}
                                                        id="booknow"
                                                        className="booknow"
                                                    >
                                                        {t('HOME.BOOKNOW')}
                                                    </a>
                                                    <a onClick={StaticMethod.openWhatsApp} className="chatnow">
                                                        <img src={whatsapp} alt="" />
                                                        {t('HOME.CHATNOW')}
                                                    </a>
                                                </div>
                                            </div>
                                            {car.is_hot_offer && (
                                                <div className="productLabel">
                                                    <div className="offer-label">{t('HOME.HOTOFFER')}</div>
                                                </div>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <button className="slider-nav slider-pre">
                                <img src={prev} alt="" />
                            </button>
                            <button className="slider-nav slider-nex">
                                <img src={next} alt="" />
                            </button>
                        </div>
                    </section>
                    {/*  <!-------------End hot deals slider--------->*/}


                    {/*  <!---------Hot offers------------>*/}
                    <section className="hot-offers">
                        <div className="container">
                            <div className="details">
                                {hotoffer.hot_offers.map((deal, index) => (
                                    <div className="box" key={index}>
                                        <a>
                                            <img
                                                onClick={() => gotoFeatures(deal.slug, deal.id)}
                                                src={deal.image_url}
                                                alt={`Offer ${index + 1}`}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/*  <!---------End Hot offers------------>*/}

                    <section className="booking-sec">
                        <div
                            className="modal fade modal-lg"
                            id="bookingModal"
                            tabIndex="-1"
                            aria-labelledby="bookingModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={close}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* {blnBookNowOpen && (
                                            <BookNow
                                                carId={carId}
                                                brandName={brandName}
                                                carName={carName}
                                                carImg={carImg}
                                                close={close}
                                            />
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="booking-sec">
                    {openBookSec && (
                        <div
                            className="modal fade modal-lg show"
                            id="bookingModal"
                            tabIndex="-1"
                            aria-labelledby="bookingModalLabel"
                            aria-hidden="true"
                            style={{ display: 'block', background: 'rgba(0,0,0,0.5)' }}
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={close}
                                            aria-label="Close"

                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <BookSection
                                            carId={carId}
                                            brandName={brandName}
                                            carName={carName}
                                            addOns={addOns}
                                            carImg={carImg}
                                            close={close}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </>
    );
}

export default HottOffers;