import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, } from 'react-router-dom';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import Home from './home/home';
import ContactUs from './common/contact';
import './App.css';
import { useTranslation, } from "react-i18next";
import { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './common/about';
import TermsCondition from './common/termscondition';
import Privacy from './common/privcy';
import Faq from './common/faq';
import BlogPage from './common/blog';
import BlogDetaisl from './common/blogdetails';
import Login from './auth/login';
import Resgister from './auth/register';
import Forget from './auth/forget';
import './assets/css/english.css';
// import './assets/css/arabic.css';
import MyProfile from './myaccount/myprofile';
import MyDocuments from './myaccount/mydocuments';
import MyBooking from './myaccount/mybooking';
// import BookingDetails from './myaccount/bookingdetails';
import ProductList from './product/productlist';
import ProductDetails from './product/productdetails';
import HottOffers from './hotoffers/hotoffer';
import { generateRoutes } from './routeUtils';
import { LanguageProvider, useLanguage } from './LanguageContext';
import HotOfferDetaisl from './hotoffers/hotoffer-details';
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from './config/environment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useScrollToTop from './shared/custom-hook';
import PlaceList from './common/place-list';
import ErrorPage from './common/errorpage';
import NotificationPage from './auth/notification';


initializeApp(environment.firebase);
const messaging = getMessaging();

function App() {
  // useScrollToTop();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const lanCheck = generateRoutes();
  const [message, setMessage] = useState(null);

  useEffect(() => {

    const currentUrl = window.location.href;
    const lowercaseUrl = currentUrl.toLowerCase();
    
    if (currentUrl !== lowercaseUrl) {
        window.location.replace(lowercaseUrl);
    }
    requestPermission();
    listen();
  }, []);


  const requestPermission = async () => {
    try {
      const currentToken = await getToken(messaging, { vapidKey: environment.firebase.vapidKey });
      // console.log(currentToken);
      if (currentToken) {

        localStorage.setItem('deviceToken', currentToken);
        // console.log("Token generated and stored:", currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error("An error occurred while retrieving token.", err);
    }
  };

  const listen = () => {
    onMessage(messaging, (payload) => {
      
      setMessage(payload.notification);
    });
  };

  if (lanCheck) {
    sessionStorage.setItem('language', currentLanguage);
    sessionStorage.removeItem('clicked');
  }


  // console.log(currentLanguage);
  return (
    <BrowserRouter>
      <div className={`language-${lanCheck}`}>
        <Header />
        <main>

          <Routes>
            {/* <Route path="/" exact element={<Home />} /> */}

            <Route path={lanCheck === 'ar' ? '/ar/contact-us' : '/en/contact-us'} element={<ContactUs />} />
            <Route path={lanCheck === 'ar' ? '/ar/about-us' : '/en/about-us'} element={<About />} />
            <Route path={lanCheck === 'ar' ? '/ar/terms-and-conditions' : '/en/terms-and-conditions'} element={<TermsCondition />} />
            <Route path={lanCheck === 'ar' ? '/ar/privacy-policy' : '/en/privacy-policy'} element={<Privacy />} />
            <Route path={lanCheck === 'ar' ? '/ar/blog' : '/en/blog'} element={<BlogPage />} />
            <Route path={lanCheck === 'ar' ? '/ar/blogdetails/:id' : '/en/blogdetails/:id'} element={<BlogDetaisl />} />
            <Route path={lanCheck === 'ar' ? '/ar/login' : '/en/login'} element={<Login />} />
            <Route path={lanCheck === 'ar' ? '/ar/faq' : '/en/faq'} element={<Faq />} />
            <Route path={lanCheck === 'ar' ? '/ar/register' : '/en/register'} element={<Resgister />} />
            <Route path={lanCheck === 'ar' ? '/ar/forget' : '/en/forget'} element={<Forget />}/>
            <Route path={lanCheck === 'ar' ? '/ar/myprofile' : '/en/myprofile'} element={<MyProfile />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/mydoc' : '/en/mydoc'} element={<MyDocuments />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/mybooking' : '/en/mybooking'} element={<MyBooking />}></Route>
            {/* <Route path={currentLanguage === 'ar' ? '/ar/bookingdetails' : '/en/bookingdetails'} element={<BookingDetails />}></Route> */}
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals/brands/:id' : '/en/cheapest-car-rentals/brands/:id'} element={<ProductList />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals' : '/en/cheapest-car-rentals'} element={<ProductList />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals/category/:id' : '/en/cheapest-car-rentals/category/:id'} element={<ProductList />}></Route>
            {/* <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals/:id/:id' : '/en/cheapest-car-rentals/:id/:id'} element={<ProductList />}></Route> */}
            <Route path={lanCheck === 'ar' ? '/ar/car-details/:id' : '/en/car-details/:id'} element={<ProductDetails />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/special-offers' : '/en/special-offers'} element={<HottOffers />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/special-offers/:id' : '/en/special-offers/:id'} element={<HotOfferDetaisl />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/our-locations' : '/en/our-locations'} element={<PlaceList />}></Route>
            <Route path={lanCheck === 'ar' ? '/ar/our-locations/:id' : '/en/our-locations/:id'} element={<PlaceList />}></Route>
            <Route path={lanCheck === 'ar' ? 'ar/cheapest-car-rentals/location/:id' : 'en/cheapest-car-rentals/location/:id'} element={<ProductList />}></Route>
            <Route path={lanCheck === 'ar' ? 'ar/shared/notification' : 'en/shared/notification'} element={<NotificationPage />}></Route>
            <Route path="/" exact element={<Navigate to={lanCheck === 'ar' ? '/ar' : '/en'} />} />
            <Route path="/en" exact element={<Home />} />
            <Route path="/ar" exact element={<Home />} />
            <Route path="*" element={<ErrorPage />} />

          </Routes>


        </main>

        <Footer />

      </div>

    </BrowserRouter>



  );
}



export default App;
